import { getDefaultOptions } from 'API/auth'
import { getExternal } from 'API/getExternal'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { navigate, PageProps } from 'gatsby'
import { useEditableHook } from 'hooks/useEditableHook'
import React from 'react'
import { CustomModal } from '../../../../../components/common/useModal'
import { toast } from 'react-toastify'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { useGetModulesHook } from 'hooks/useGetModulesHook'

const resourceDates = [{ text: 'Start Time:', name: 'visible_from' }]

const External: React.FC<PageProps> = ({ params: { id: course }, location }) => {
	let ref = React.useRef<HTMLButtonElement | null>(null)
	const { type_of_course, stable } = useGetCourseDetails(course)
	const { modules } = useGetModulesHook({ course, stable, type_of_course })

	const { edit, elem_id } = useEditableHook({ location })
	const [editable, setEditable] = React.useState<any>({})
	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getExternal(elem_id, 'Teacher')
				if (asset) {
					console.log('asset', asset)
					setEditable(asset)
				}
			}
			run()
		}
	}, [edit, elem_id])
	return (
		<>
			<CustomModal
				header='Add External Link'
				onClick={async () => {
					if (edit === 'true') {
						if (ref.current) {
							ref.current.click()
						}
					}
					console.log(ref)
					if (ref.current) {
						ref.current.click()
					}
				}}
			>
				<Form
					editableState={editable}
					getRef={(_ref) => {
						if (_ref) ref = _ref
					}}
					action={async ({ state }) => {
						if (edit === 'true') {
							if (edit === 'true') {
								const external = await handleApi<{ uid: string }>(
									axios.patch(
										getUrl('asset/external'),
										{
											...state,
											course,
										},
										getDefaultOptions()
									)
								)
								if (external) navigate(`/course/${course}/activities/external/${external.uid}`)
							}
						} else {
							const external = await handleApi<{ uid: string }>(
								axios.post(
									getUrl('asset/external'),
									{
										...state,
										course,
									},
									getDefaultOptions()
								)
							)
							if (external) navigate(`/course/${course}/activities/external/${external.uid}`)
						}
					}}
					text={'Add External Link'}
					fields={[
						{
							type: 'text',
							label: 'Name',
							name: 'name',
						},
						{
							type: 'textarea',
							label: 'Description',
							name: 'description',
						},
						{ label: 'Link', name: 'link', type: 'url' },
						...resourceDates.map((elem) => ({
							type: 'datetime-local',
							label: elem.text,
							name: elem.name,
						})),
						modules.length > 0
							? {
									type: 'select',
									selection: modules,
									name: 'module',
									label: 'Module',
							  }
							: {
									type: 'select',
									selection: [],
									label: '',
									name: '',
							  },
					]}
				></Form>
			</CustomModal>
		</>
	)
}

export default External
