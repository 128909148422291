import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { UserType } from 'types/UserAdditionMetaData'
import { LinkType } from '../pages/course/[id]/activities/external/[external_id]'

export async function getExternal(external_id: string, viewAs: string) {
	return await handleApi<LinkType>(
		axios.get(getUrl(`asset/external?external_id=${external_id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions())
	)
}

export async function getSubmissionExternal(external_id: string, course_id: string) {
	return await handleApi<(UserType & { isOpened: boolean; last_open_date: Date })[]>(
		axios.get(getUrl(`asset/get_external_submission_list?external_id=${external_id}&course_id=${course_id}`), getDefaultOptions())
	)
}
